import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/Layout'

import './404.scss'


// =============================================================================
const NotFoundPage = () => (
  <Layout pageTitle="Not found...">
    <div class="NotFound">
      <h1 className="title">There&rsquo;s nothing here...</h1>
      <p>
        There&rsquo;s plenty of great stuff on this site, but sadly not here.
      </p>
      <p>
        Try <Link to="/">going back to the home page</Link>.
      </p>
    </div>
  </Layout>
)

// -----------------------------------------------------------------------------
export default NotFoundPage
